import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';

import App from './app/AppComponent';
import Dashboard from './dashboard/components/Dashboard.js';
import Organization from './organization/components/Organization.js';
import Tools from './tools/components/Tools.js';
import User from './user/components/User.js';
import Login from './app/Login';
import Stats from './stat/components/Dashboard.js';
import TeamStat from './stat/components/TeamStat.js';

function Root(props) {
    const { history } = props;
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/">
                    <Redirect from="/" to="/user" />
                </Route>
                <Route exact path="/sso/login" component={Login} />
                <Route path="/">
                    <App>
                        <Route exact path="/user" component={User} />
                        <Route exact path="/report" component={Dashboard} />
                        <Route exact path="/organization" component={Organization} />
                        <Route exact path="/billing_group" component={Organization} />
                        <Route exact path="/tools" component={Tools} />
                        <Route exact path="/stats" component={Stats} />
                        <Route exact path="/stats/teams/:team_id" component={TeamStat} />
                    </App>
                </Route>
            </Switch>
        </Router>
    );
}

export default Root;
