import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Navbar from './Navbar.js';

import Auth from './Auth';

class AppComponent extends Component {
    async redirectToKeycloak() {
        const authorizationUri = await Auth.getAuthorizationUri();
        window.location = authorizationUri;
    }

    componentWillMount() {
        if (!Auth.authenticate()) {
            this.redirectToKeycloak();
        }
    }

    render() {
        return (
            <div>
                <Navbar />
                {this.props.children}
            </div>
        );
    }
}

export default withRouter(AppComponent);
